@import "../index.scss";

$darker: rgb(210, 210, 210);
.home-main {
	@include flex;
	flex-direction: column;
	width: 100vw;
	min-height: 100vh;
	nav {
		@include flex;
		width: 100%;
		height: 100px;
		background-color: $blue-accent;
		.nav-title {
			color: white;
			font-size: 1.6rem;
			.board {
				color: white;
			}
			.master {
				color: rgb(150, 150, 150);
			}
		}
	}
	main {
		flex: 1;
		@include flex;
		flex-direction: column;
		max-width: $max-width;
		width: 100%;
		.info {
			flex: 1;
			@include flex;
			flex-direction: column;
			height: 100%;
			min-height: 250px;
			.card-cont {
				@include flex;
				justify-items: flex-start;
				.card {
					@include flex;
					max-width: 250px;
					width: 100%;
					margin: 0 10px;
					img {
						width: 50px;
						margin-right: 20px;
					}
				}
			}
		}
		.join {
			flex: 1;
			width: 100%;
			height: 100%;
			@include flex;
			flex-direction: column;
			.join-cont {
				overflow: hidden;
				min-width: 300px;
				padding: 20px;
				border-radius: 10px;
				border: 2px solid rgb(209, 209, 209);
				background-color: rgb(235, 235, 235);
				.join-title {
					text-align: center;
					font-size: 1.5rem;
					padding: 20px 0;
					margin: -20px;
					margin-bottom: 20px;
					border-bottom: 2px solid black;
					color: white;
					background-color: $blue-accent;
				}
				p {
					@include flex;
					justify-content: flex-start;
					font-size: 0.95rem;
					width: 100%;
					margin-top: 0;
					margin-bottom: 5px;
					text-align: left;
					span {
						margin-left: auto;
						color: rgb(192, 11, 11);
					}
				}
				.inputs {
					@include flex;
					flex-direction: column;
					margin-bottom: 20px;
					input {
						text-transform: capitalize;
						width: 100%;
						margin-bottom: 10px;
						border: 1px solid black;
						background-color: white;
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
				.rooms {
					overflow-y: auto;
					flex-direction: column;
					width: 100%;
					max-height: 200px;
					height: 200px;
					margin-bottom: 20px;
					border-radius: 5px;
					border: 1px solid black;
					background-color: white;
					.room {
						user-select: none;
						cursor: pointer;
						font-size: 0.9rem;
						width: 100%;
						padding: 7px;
						transition: 0.2s;
						@include flex;
						justify-content: space-between;
						span:last-child {
							margin-right: 10px;
							// font-weight: bold;
						}
						&:hover {
							transition: 0.2s;
							background-color: rgb(220, 220, 220);
						}
					}
				}
				.actions {
					button {
						width: 100%;
					}
				}
			}
		}
	}
	footer {
		margin-top: 100px;
	}
}

// .join-main {
// 	@include flex;
// 	flex-direction: column;
// 	padding: 20px;
// 	border-radius: 5px;
// 	border: 2px solid slategrey;
// 	background-color: rgb(211, 211, 211);
// 	h1 {
// 		text-align: center;
// 		width: 100%;
// 		padding-bottom: 20px;
// 		border-bottom: 2px solid slategrey;
// 	}
// 	.input-area {
// 		input {
// 			border: 1px solid slategrey;
// 			background-color: white;
// 			&:first-child {
// 				margin-right: 10px;
// 			}
// 		}
// 	}
// 	.button-area {
// 		@include flex;
// 		width: 100%;
// 		justify-content: space-between;
// 		button {
// 			margin-left: auto;
// 		}
// 		select {
// 			outline: none;
// 			flex: 1;
// 			padding: 5px;
// 			margin-right: 10px;
// 			border-radius: 5px;
// 			border: 1px solid slategrey;
// 		}
// 	}
// }
