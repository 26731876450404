@import url("https://fonts.googleapis.com/css?family=Alegreya+Sans+SC:300,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap");

::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background-color: darken(white, 40%);
}
::-webkit-scrollbar-thumb {
  background-color: darken(white, 70%);
  border-radius: 5px;
}
::-webkit-scrollbar:horizontal {
  height: 7px;
}
::-webkit-scrollbar-track:horizontal {
  background-color: transparent;
}
::-webkit-scrollbar-thumb:horizontal {
  background-color: darken(white, 70%);
  border-radius: 5px;
}

@mixin flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

$max-width: 1000px;
$app-bg: white;
$blue-accent: rgb(14, 17, 26);
$local-msg: rgb(29, 50, 75);
$foreign-msg: rgb(21, 31, 49);
$room-accent: rgb(215, 215, 215);

* {
  box-sizing: border-box;
  line-height: 1.5rem;
  font-family: Roboto;
}

body {
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}

h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
}

.app {
  background-color: $app-bg;
}
