@import "../../index.scss";

.chat {
	@include flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-end;
	height: 100%;
	.chat-cont {
		flex: 1;
		position: relative;
		overflow-x: scroll;
		min-width: 400px;
		max-width: 400px;
		min-height: 600px;
		max-height: 600px;
		@include flex;
		flex-direction: column;
		justify-content: flex-start;
		border-radius: 5px;
		border: 2px solid slategrey;
		background-color: $room-accent;
	}
	.message-area {
		flex: 1;
		@include flex;
		align-items: flex-end;
		justify-content: flex-end;
		flex-direction: column;
		width: 100%;
		margin: 10px 0;
		.message {
			@include flex;
			flex-direction: column;
			width: fit-content;
			margin: 0 10px;
			margin-bottom: 2px;
			max-width: 80%;
			.sender {
				text-transform: capitalize;
				font-size: 0.8rem;
				margin: 0 10px;
				color: rgb(0, 0, 0);
			}
			.body {
				font-size: 0.9rem;
				padding: 5px 10px;
				border-radius: 15px;
				color: white;
			}
		}
		.local-color {
			background-color: $local-msg;
		}
		.foreign-color {
			background-color: $foreign-msg;
		}
		.local-align {
			align-self: flex-end;
		}
		.foreign-align {
			align-self: flex-start;
		}
	}
	.input-area {
		position: sticky;
		bottom: 0;
		left: 0;
		@include flex;
		justify-content: space-between;
		width: 100%;
		background-color: $room-accent;
		input {
			flex: 1;
			margin: 0 10px;
		}
		button {
			margin-right: 10px;
		}
	}
}
