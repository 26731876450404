@import "../index.scss";

.room-main {
	@include flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	min-height: 100vh;
	nav {
		position: relative;
		@include flex;
		width: 100%;
		height: 100px;
		background-color: $blue-accent;
		.nav-cont {
			font-size: 1.4rem;
			cursor: default;
			@include flex;
			justify-content: space-between;
			width: 100%;
			max-width: $max-width;
			.nav-title {
				color: white;
				.board {
					color: white;
				}
				.master {
					color: rgb(150, 150, 150);
				}
			}
			.nav-room {
				color: white;
			}
		}
		.leave-room {
			position: fixed;
			bottom: 10px;
			left: 10px;
		}
	}

	h1 {
		text-align: center;
		width: 100%;
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 2px solid slategrey;
	}
	main {
		flex: 1;
		user-select: none;
		@include flex;
		align-items: flex-start;
		width: 100%;
		height: 100%;
		margin-top: 20px;
		.session {
			@include flex;
			flex-direction: column;
			justify-content: flex-start;
			min-width: 600px;
			height: 100%;
			margin-right: 20px;
			.lobby-main,
			.editors-main {
				width: 100%;
				flex: 1;
			}
		}
		.chat {
			.toggle {
				text-align: right;
				button {
					margin-bottom: 20px;
				}
			}
		}
	}
}
