@import "../../index.scss";

.editors-main {
	.controls {
		@include flex;
		justify-content: flex-end;
		margin-bottom: 20px;
		.controls-placeholder {
			height: 34px;
		}
		.status {
			flex: 1;
			margin: 0;
		}
	}
	.editors {
		.editor-cont {
			@include flex;
			flex-direction: column;
			padding: 20px;
			max-width: 600px;
			min-width: 600px;
			border-radius: 5px;
			border: 2px solid slategrey;
			background-color: $room-accent;
			.player-forfeited {
				span {
					text-transform: capitalize;
				}
			}
			.head {
				@include flex;
				justify-content: flex-end;
				width: 100%;
				* {
					font-size: 1.2rem;
					margin: 0;
				}
				.name {
					flex: 1;
					text-transform: capitalize;
				}
				.winner {
					animation: win 2s infinite;
				}
			}
			.body {
				width: 100%;
				.text-area {
					margin: 25px 0;
					text-align: left;
					letter-spacing: 0.9px;
					color: black;
					span {
						font-size: 1.1rem;
					}
					.current {
						font-weight: 600;
						color: rgb(0, 0, 0);
					}
					.correct {
						color: rgb(4, 53, 117);
					}
					.incorrect {
						color: rgb(160, 4, 4);
					}
				}
				.input-area {
					@include flex;
					width: 100%;
					input {
						flex: 1;
						width: 100%;
					}
					button {
						min-width: 75px;
					}
				}
				.stats-cont {
					@include flex;
					flex-direction: column;
					justify-content: flex-start;
					width: 100%;
					.stats {
						@include flex;
						flex-direction: column;
						margin: 0 !important;
						p {
							margin: 0;
							&:first-child {
								margin-bottom: 5px;
							}
						}
					}
				}
			}
		}
		&:first-child .editor-cont {
			margin-bottom: 20px;
		}
	}
}

$grey: $room-accent;
$lightgrey: rgb(86, 137, 179);
$lightblue: rgb(19, 114, 192);
$darkblue: rgb(17, 49, 80);

.on-load {
	@include flex;
	position: relative;
	width: 100%;
	opacity: 0;
	animation: fade 1s forwards;
	.time {
		position: absolute;
		left: 50%;
		transform: translate(-50%);
	}
}

@keyframes fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.spinner {
	margin: 0 auto;
	pointer-events: none;
	width: 40px;
	height: 40px;
	border: 2px solid transparent;
	border-color: $grey;
	border-top-color: $lightgrey;
	border-radius: 50%;
	animation: spin 1s, colour-wheel 3s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}

@keyframes spin {
	0% {
		transform: rotateZ(0);
	}
	100% {
		transform: rotateZ(360deg);
	}
}

@keyframes colour-wheel {
	0% {
		border-top-color: $lightgrey;
	}
	33% {
		border-top-color: $lightblue;
	}
	66% {
		border-top-color: $darkblue;
	}
	100% {
		border-top-color: $lightgrey;
	}
}

@keyframes win {
	0% {
		color: rgb(49, 0, 0);
	}
	20% {
		color: rgb(75, 0, 65);
	}
	40% {
		color: rgb(47, 0, 155);
	}
	60% {
		color: rgb(11, 119, 138);
	}
	80% {
		color: rgb(36, 128, 0);
	}
	100% {
		color: rgb(59, 58, 0);
	}
}
