@import "../../index.scss";

.users {
	position: sticky;
	top: 0;
	width: 100%;
	padding: 5px 0;
	border-bottom: 2px solid slategrey;
	background-color: $blue-accent;
	.user {
		@include flex;
		justify-content: space-between;
		p {
			color: white;
			padding: 0 10px;
			margin: 5px;
			text-transform: capitalize;
		}
		span {
			cursor: pointer;
			color: white;
			padding: 5px;
			margin-right: 15px;
			transition: 0.2s;
			&:hover {
				transition: 0.2s;
				color: rgb(206, 56, 56);
			}
		}
	}
}
