@import "../../index.scss";

.lobby-main {
	.controls {
		@include flex;
		justify-content: space-between;
		width: 100%;
		padding-bottom: 20px;
		.join-button {
			margin-right: 10px;
		}
		.control-placeholder {
			height: 34px;
		}
		.practice-button {
			margin-left: auto;
		}
	}
	.body {
		align-self: flex-end;
		@include flex;
		flex-direction: column;
		justify-content: space-evenly;
		width: 100%;
		padding-bottom: 20px;
		border-radius: 5px;
		border: 2px solid slategrey;
		background-color: $room-accent;
		.header {
			width: 100%;
			margin: 0;
			padding-top: 20px;
			color: white;
			background-color: $blue-accent;
		}
		.players {
			padding: 40px 0;
			p {
				margin: 0;
			}
			@include flex;
			.player {
				margin: 0 10px;
				width: 100px;
				text-align: center;
				text-transform: capitalize;
			}
			.player-placeholder {
				height: 20px;
				width: 100px;
				margin: 0 10px;
				border-radius: 5px;
				background-color: slategray;
				animation: placeholder 3s infinite;
			}
		}
	}
}

@keyframes placeholder {
	0% {
		background-color: slategray;
	}
	33% {
		background-color: rgba(93, 136, 156, 0.52);
	}
	66% {
		background-color: rgba(108, 87, 143, 0.52);
	}
	100% {
		background-color: slategray;
	}
}
